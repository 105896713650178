import axios from 'axios';

class ApiService {

  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Retrieve the token from localStorage if it exists and set it in the headers
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      this.api.defaults.headers['Authorization'] = `Basic ${storedToken}`;
      console.log('Retrieved token from localStorage:', storedToken);
    }

    this.api.interceptors.request.use(

      (config) => {

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // Method to set credentials and update Authorization header
  setCredentials(username, password) {
    const token = btoa(`${username}:${password}`);
    this.api.defaults.headers['Authorization'] = `Basic ${token}`;
    localStorage.setItem('authToken', token);
  }


  async getWithRequestParam(endpoint, params = {}) {
    try {
      const response = await this.api.get(`${endpoint}?params=${params}`);
      return response.data.body;
    } catch (error) {
      throw error;
    }
  }

  async getWithPathVariable(endpoint, name) {
    try {
      const url = `${endpoint}/${name}`;
      const response = await this.api.get(url);
      return response.data.body;
    } catch (error) {
      throw error;
    }
  }

  async get(endpoint) {
    try {
      const response = await this.api.get(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async post(endpoint, data) {
    try {
      const response = await this.api.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async put(endpoint, data) {
    try {
      const response = await this.api.put(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(endpoint) {
    try {
      const response = await this.api.delete(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async patch(endpoint, data) {
    try {
      const response = await this.api.patch(`${endpoint}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async exportFile(endpoint, name) {
    try {

      const response = await this.api.get(endpoint, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name} Product Definitions Doc.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting file:', error);
      alert('Failed to export the file. Please try again.');
    }
  }

};




const apiService = new ApiService('http://localhost:8084/api/v1');

export default apiService;
