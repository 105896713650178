import React, { useEffect, useState, useRef } from 'react';
import { IoIosSearch, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Input } from "@/components/ui/input";
import DataCard from '@/components/main/DataCard';
import noDataImage from '../assets/no-data.jpg';
import { Button } from '@/components/ui/button';
import CreateRelease from '@/components/main/CreateRelease';
import apiService from '@/api/ApiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  faArrowLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { useLocation } from 'react-router-dom';
import ReleaseAppUtils from '../utils/ReleaseAppUtils';
import ReleaseAppConstants from '../utils/ReleaseAppConstants';

const Releases = () => {

  const [releases, setReleases] = useState([]);
  const [page, setPage] = useState(0);
  const itemsPerPage = 6;
  const [searchTerm, setSearchTerm] = useState('');
  const [market, setMarket] = useState(null);
  const [status, setStatus] = useState(null);
  const [stage, setStage] = useState(null);
  const [isCreateRelease, setIsCreateRelease] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedRelease, setSelectedRelease] = useState();
  const location = useLocation();
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    fetchInitialDataAndStore();
  }, []);

  const fetchInitialDataAndStore = async () => {
    if (!ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA)) {
      try {
        const response = await apiService.get(ReleaseAppConstants.GENERAL_INIT);

        if (response && response.data && response.data.body) {
          ReleaseAppUtils.setItem(ReleaseAppConstants.INITIAL_DATA, response.data.body);
          setInitialData(ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA));
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    } else {
      setInitialData(ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA));
    }
  };


  const fetchComponents = (page) => {

    const filter = {};
    if (market) {
      filter.market = market;
    }
    if (status) {
      filter.status = status;
    }
    if (stage) {
      filter.stage = stage;
    }

    const params = {
      pageSize: itemsPerPage,
      pageNumber: page,
      searchString: searchTerm,
      filters: filter
    };

    const encodedParams = encodeURIComponent(JSON.stringify(params));

    apiService.getWithRequestParam(ReleaseAppConstants.RELEASES_API, encodedParams)
      .then(response => {
        if (response && response.data) {
          setReleases(response.data);
          setTotalPages(response.totalPages);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  useEffect(() => {
    fetchComponents(page);
  }, [page, searchTerm, market, status, stage]);


  const handleSelectChange = ({ target }) => {

    const { name, value } = target;
    const updateState = value === "all" ? null : value;

    if (name === "status") {
      setStatus(updateState);
    } else if (name === "market") {
      setMarket(updateState);
    }
    else {
      setStage(updateState)
    }
    setPage(0);
  };

  const handleSearch = (event) => {

    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handlePrevious = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handleCreateRelease = () => {
    setSelectedRelease(null);
    setIsCreateRelease(true);
  };

  const handleViewReleases = () => {
    setIsCreateRelease(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setIsCreateRelease(queryParams.get('isCreateRelease') === 'true');
  }, [location.search]);

  useEffect(() => {
    if (!isCreateRelease) {
      const delayFetch = setTimeout(() => {
        fetchComponents(0);
      }, 100);

      return () => clearTimeout(delayFetch);
    }
  }, [isCreateRelease]);


  const getOptions = (type) => {
    const options = initialData?.[type] || [];
    const allOption = { value: 'all', label: `All ${type.toUpperCase()}` };
    let allOptions = '';
    if (type === ReleaseAppConstants.STATUS) {
      allOptions = [allOption, ...Object.keys(options).map(key => ({ value: key, label: key }))];
    } else {
      allOptions = [allOption, ...options.map(option => ({ value: option, label: option }))];
    }

    return allOptions.map(({ value, label }) => (
      <SelectItem key={value} value={value}>{label}</SelectItem>
    ));
  };


  return (
    <div className='body-container'>
      <div className='data-container'>
        {!isCreateRelease ? (
          <>
            <div className='relative mt-4 mb-4 w-full md:w-2/4 mx-auto flex items-center'>
              <div className='relative flex-grow'>
                <Input
                  className='search-bar w-full pr-10 border rounded-xl shadow'
                  type="text"
                  id="searchbox"
                  placeholder="Search Releases"
                  onChange={handleSearch}
                />
                <IoIosSearch className='absolute top-1/2 right-2 transform -translate-y-1/2 text-4xl text-gray-500 p-1 rounded' />
              </div>
              <Button
                className="text-white rounded-lg ml-4 w-24 md:w-32 lg:w-36 bg-gray-700"
                onClick={handleCreateRelease}
              >
                Create Release
              </Button>
            </div>


            <div className='flex mb-3 w-full md:w-2/4 mx-auto flex gap-4'>
              <Select onValueChange={value => handleSelectChange({ target: { name: 'market', value } })} defaultValue='all'>
                <SelectTrigger className="w-full h-8 shadow border rounded-lg bg-gray-100 ">
                  <SelectValue placeholder="Market" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {getOptions(ReleaseAppConstants.CUSTOMERS)}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <Select onValueChange={value => handleSelectChange({ target: { name: 'status', value } })} defaultValue='all'>
                <SelectTrigger className="w-full h-8 shadow border rounded-lg bg-gray-100" >
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {getOptions(ReleaseAppConstants.STATUS)}
                  </SelectGroup>
                </SelectContent>
              </Select>

              <Select onValueChange={value => handleSelectChange({ target: { name: 'stage', value } })} defaultValue='all'>
                <SelectTrigger className="w-full h-8 shadow border rounded-lg bg-gray-100 ">
                  <SelectValue placeholder="Stage" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {getOptions(ReleaseAppConstants.STAGES)}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div className="section-heading-underline"></div>

            <div className="relative flex items-center mt-4">
              <div className="absolute left-0 ml-7 transform translate-y-1/5 md:block hidden ">
                {page > 0 && (
                  <button onClick={handlePrevious} className="transition-transform duration-200 ease-in-out transform hover:scale-110">
                    <IoIosArrowBack size={80} className="text-gray-700" />
                  </button>
                )}
              </div>
              <div className="w-3/4 mx-auto mt-5 mb-10 grid md:grid-cols-3 gap-10 h-[350px]">
                {releases && releases.length > 0 ? (
                  releases.map((release, index) => (
                    <div key={index}>
                      <DataCard release={release} setIsViewSelectedRelease={setIsCreateRelease} setSelectedRelease={setSelectedRelease} />
                    </div>
                  ))
                ) : (
                  <div className="md:col-span-3 flex justify-center items-center">
                    <img src={noDataImage} alt="No data" className="w-80 h-full object-contain" />
                  </div>
                )}
              </div>

              <div className="absolute right-0 mr-7 transform translate-y-1/5 md:block hidden">
                {page < totalPages - 1 && (
                  <button onClick={handleNext} className="transition-transform duration-200 ease-in-out transform hover:scale-110">
                    <IoIosArrowForward size={80} className="text-gray-700" />
                  </button>
                )}
              </div>
            </div>
            <div className=" pt-20 font-semibold">
              {totalPages !== 0 && <p>Showing page {page + 1} of {totalPages}</p>}
            </div>
          </>
        ) : (
          <div className="p-4">
            <div className="flex justify-between ">
              <Button variant="ghost" className="flex items-center space-x-2 hover:bg-gray-100 hover:text-gray-800" onClick={handleViewReleases}>
                <FontAwesomeIcon icon={faArrowLeft} />
                <span>View Releases</span>
              </Button>
            </div>
            <CreateRelease setIsCreateRelease={setIsCreateRelease} release={selectedRelease} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Releases;
