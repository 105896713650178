class ReleaseAppConstants {

    static INITIAL_DATA = 'initialData';
    static GENERAL_INIT = '/general/init';
    static RELEASES_API = '/releases';
    static COMPONENT_TYPE = 'COMPONENT_TYPE';
    static CUSTOMERS = 'CUSTOMERS';
    static PACKAGE_TYPE = 'PACKAGE_TYPE';
    static STAGES = 'STAGES';
    static STATUS = 'STATUS';
    static SAVE_BUTTON_TEXT = 'Save';
    static UPDATE_BUTTON_TEXT = 'Update';
    static CLONE_BUTTON_TEXT = 'Clone';
    static SUPERSEDED = 'SUPERSEDED';
    static FINALIZED = 'FINALIZED';
    static ARCHIVED = 'ARCHIVED';
    static CREATE_RELEASE = 'Create Release';
    static CLONING_FROM = 'Cloning From';
    static PACKAGE_TYPE = 'PACKAGE_TYPE';
    static COMPONENTS_API = '/components';
    static PACKAGES_API = '/packages';
}

Object.freeze(ReleaseAppConstants);

export default ReleaseAppConstants;