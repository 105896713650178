// MainNav.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegCalendarAlt, FaBoxOpen, FaCogs } from 'react-icons/fa';
import logo from '../../assets/logo.png';
import { Button } from '@/components/ui/button';
import { GoGitCompare } from "react-icons/go";

const mainNavItems = [
  { name: 'Releases', icon: <FaRegCalendarAlt />, path: '/releases' },
  // { name: 'Packages', icon: <FaBoxOpen />, path: '/packages' },
  { name: 'Components', icon: <FaCogs />, path: '/components' },
  { name: 'Compare', icon: <GoGitCompare />, path: '/compare' },
];

export default function MainNav() {
  const location = useLocation();

  return (
    <div className="mr-4 hidden gap-4 md:flex justify-center items-center">
      <img src={logo} alt="Logo" className="ml-2 w-18 h-8 mr-6 mb-2" />
      {mainNavItems.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          className={`flex items-center gap-4 text-black transition duration-300 ease-in-out relative group ${location.pathname === item.path ? 'font-semibold' : 'font-normal'}`}
        >
          <span className="flex items-center gap-2 mr-4">
            {React.cloneElement(item.icon, { className: 'text-lg' })}
            <span className="relative">
              <span className="text-sm font-semibold">{item.name}</span>
              <span
                className={`absolute left-0 bottom-[-2px] w-full h-[.5px] transform transition-transform duration-300 ${location.pathname === item.path ? 'bg-black scale-x-100' : 'bg-black scale-x-0 '
                  }`}
              />
            </span>
          </span>
        </Link>
      ))}
    </div>
  );
}
