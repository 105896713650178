import React from 'react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

import { IoCubeOutline } from 'react-icons/io5';
import { DialogTitle, DialogDescription } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

const SearchDialog = ({ open, setOpen, data = [], onItemClick }) => {

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <VisuallyHidden>
        <DialogTitle>Command Dialog</DialogTitle>
        <DialogDescription>Description of the Command Dialog</DialogDescription>
      </VisuallyHidden>
      <CommandInput placeholder="Type a command or search..." />
      <CommandEmpty>No results found.</CommandEmpty>
      <CommandList>
          <CommandGroup heading="Suggestions">
            {data.map((item, index) => (
              <CommandItem key={index} onSelect={() => onItemClick(item)}>
                <IoCubeOutline className="mr-5 h-4 w-4" />
                {item.name}
              </CommandItem>
            ))}
          </CommandGroup>

      </CommandList>
    </CommandDialog>
  );
};

export default SearchDialog;
