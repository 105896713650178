import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@/components/ui/button';
import { Textarea } from "@/components/ui/textarea"
import { Label } from "@/components/ui/label";
import { PackageCard } from './PackageCard';
import { Input } from "@/components/ui/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit } from "lucide-react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FiCheckCircle } from 'react-icons/fi';
import { RiNumber1, RiNumber2 } from "react-icons/ri";
import { FaFingerprint } from "react-icons/fa";
import { PiFingerprintSimpleFill } from "react-icons/pi";

import AddIcon from './AddIcon';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  faSave,
  faCancel,
  faRefresh,
  faPlus,
  faClone,
  faFileExport
} from "@fortawesome/free-solid-svg-icons";

import SearchDialog from './SearchDialog';
import apiService from '@/api/ApiServices';
import { useToast } from "@/components/ui/use-toast";
import CreatePackage from './CreatePackage';
import PopupDialog from './PopupDialog';
import PackageDetails from './PackageDetails';
import PackageComponentDetails from './PackageComponentDetails';
import PackagePreview from './PackagePreview';
import PackageTable from './PackageTable';
import ReleaseAppUtils from '../../utils/ReleaseAppUtils';
import ReleaseAppConstants from '../../utils/ReleaseAppConstants';

const CreateRelease = ({ setIsCreateRelease, release }) => {

  const initialReleaseState = {
    description: '',
    lastUpdated: '',
    market: '',
    name: '',
    packages: [],
    stage: '',
    status: '',
    type: '',
    version: '',
    fingerprintUrlLocation: ''
  };

  const currentRelease = useRef(initialReleaseState);
  const [currentReleaseCopy, setCurrentReleaseCopy] = useState(initialReleaseState);
  const [openCommand, setOpenCommand] = useState(false);
  const [allPackageData, setAllPackageData] = useState([]);
  const { toast } = useToast();
  const [isCreatePackage, setIsCreatePackage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [page, setPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [selectedPackageForEdit, setSelectedPackageForEdit] = useState({});
  const [initialData, setInitialData] = useState(ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA));

  useEffect(() => {
    fetchCurrentRelease()
    setPage(0);
    setIsCloning(false);
    setIsEditing(false);
    setIsCreatePackage(false);
    setOpenCommand(false);
    setSelectedPackageForEdit({});
    setItemsPerPage(release ? 6 : 5);
  }, []);

  const fetchPackages = () => {
    const params = {
      pageSize: 100,
      pageNumber: 0,
      searchTerm: "",
      filters: {}
    };

    const encodedParams = encodeURIComponent(JSON.stringify(params));


    apiService.getWithRequestParam("/packages", encodedParams)
      .then((response) => {
        setAllPackageData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  };

  const fetchCurrentRelease = () => {

    if (release) {
      apiService.getWithPathVariable("/releases", release.name)
        .then(response => {
          currentRelease.current = response;
          setCurrentReleaseCopy(response);
        })
        .catch(error => {
          console.error('Error getting Release', error);
        });
    }
  }

  const handleItemClick = (item) => {
    setOpenCommand(false);
    const updatedItems = [...currentReleaseCopy.packages, item];
    setCurrentReleaseCopy(prevReleaseData => ({
      ...prevReleaseData,
      packages: updatedItems,
    }));
  };

  const handleRemoveItem = (item) => {
    const updatedItems = currentReleaseCopy.packages.filter(selectedItem => selectedItem.name !== item.name);
    setCurrentReleaseCopy(prevReleaseData => ({
      ...prevReleaseData,
      packages: updatedItems,
    }));
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    fetchPackages();
    setOpenCommand(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    isDisabled(name);
    setCurrentReleaseCopy({
      ...currentReleaseCopy,
      [name]: value,
    });

  };

  const resetForm = () => {
    setCurrentReleaseCopy(currentRelease.current);
  }

  const handleCreateRelease = () => {
    apiService.post('/releases', currentReleaseCopy)
      .then(response => {
        console.log('Release created successfully:', response.data);
        resetForm();

        toast({
          title: "New Release Created",
          description: (
            <span>
              Your release <strong>{currentReleaseCopy.name}</strong> has been successfully created.
            </span>
          ),
          duration: 5000,
          className: 'success-toast',
        });
      })
      .catch(error => {
        console.error('Error creating release:', error);

        toast({
          title: "Failed to Create Release",
          description: "An error occurred while creating the release. Please try again.",
          className: 'error-toast',
          duration: 5000,
        });
      });

    setIsCreateRelease(false);
  };


  const handleUpdateRelease = () => {

    apiService.patch('/releases', currentReleaseCopy)
      .then(response => {
        console.log('Release updated successfully:', response.data);
        resetForm();

        toast({
          title: "Release Updated",
          description: (
            <span>
              Your release <strong>{currentReleaseCopy.name}</strong> has been successfully updated.
            </span>
          ),
          duration: 5000,
          className: 'success-toast',
        });
      })
      .catch(error => {
        console.error('Error updating release:', error);
        toast({
          title: "Failed to Update Release",
          description: "An error occurred while updating the release. Please try again.",
          className: 'error-toast',
          duration: 5000,
        });
      });

    setIsCreateRelease(false);
  };

  const handleReset = () => {
    setCurrentReleaseCopy(currentRelease.current);
    setPage(0);
  }

  const handleCancelRelease = () => {
    setCurrentReleaseCopy(initialReleaseState);
    setIsCreateRelease(false);
  }

  const handleCreatePackage = () => {
    setIsCreatePackage(true);
    setSelectedPackageForEdit(null);
  }


  const handleEdit = () => {
    setIsEditing(true);
    setItemsPerPage(5);
  };

  const handleClone = () => {
    setItemsPerPage(5);
    setIsEditing(true);
    setIsCloning(true);
  };

  const setPageData = () => {
    const start = page * itemsPerPage;
    const end = start + itemsPerPage;
    const paginated = currentReleaseCopy ? currentReleaseCopy.packages.slice(start, end) : [];
    setPaginatedData(paginated);
  };

  useEffect(() => {
    setPageData();
  }, [currentReleaseCopy, page]);

  useEffect(() => {
    if (isEditing) {
      setPageData();
    }
  }, [itemsPerPage]);

  const createPackagePages = [
    {
      icon: <RiNumber1 size={24} />,
      title: "Create Your Package",
      description: "Start crafting your new package with all the details.",
      content: <PackageDetails />,
    },
    {
      icon: <RiNumber2 size={24} />,
      title: "Manage Your Components",
      description: "Easily add or remove components to suit your needs.",
      content: <PackageComponentDetails />,
    },
    {
      icon: <FiCheckCircle size={24} />,
      title: "Preview Your Package",
      description: "Ensure everything is perfect before saving your package.",
      content: <PackagePreview />,
    }

  ];

  const handleExport = () => {
    const name = release.name;

    apiService.exportFile(`/releases/excel/${name}`, name)
      .catch(error => {
        console.error('Error exporting file:', error);
        alert('Failed to export the file. Please try again.');
      });
  };

  const handleDownloadFingerprint = () => {
    const fingerprintUrlLocation = release.fingerprintUrlLocation;
    if (fingerprintUrlLocation) {
      console.log("Finger Print URL: " + fingerprintUrlLocation);
      const link = document.createElement('a');
      link.href = fingerprintUrlLocation;
      link.download = '';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } else {
      console.error("Fingerprint location URL is not defined");
    }
  }

  function isDisabled(field) {
    const isNewRelease = currentReleaseCopy.name === '';
    switch (field) {
      case 'reset':
        return isResetDisabled(isNewRelease);
      case 'save':
        return isSaveDisabled(isNewRelease);
      case 'type':
        return isTypeDisabled(isNewRelease);
      case 'version':
        return isVersionDisabled(isNewRelease);
      case 'status':
        return isStatusDisabled(isNewRelease);
      case 'market':
        return isMarketDisabled(isNewRelease);
      case 'description':
        return isDescriptionDisabled(isNewRelease);
      case 'packs':
        return isPacksDisabled(isNewRelease);
      case 'add-package':
        return isAddPackageDisabled(isNewRelease);
      case 'package-card':
        return isPackageCardDisabled(isNewRelease);
      case 'action-buttons':
        return isActionButtonDisabled();
      case 'fingerprint-button':
        return isFingerprintButtonDisabled();
      case 'edit':
        return isEditButtonVisible();
      default:
        return false;
    }
  }

  const isEditButtonVisible = () => {
    const isSuperseded = currentRelease.current.status === ReleaseAppConstants.SUPERSEDED;

    return isSuperseded;
  }

  const isResetDisabled = (isNewRelease) => {
    const changesDetected = hasChanges(currentReleaseCopy);
    const shouldEnableReset = (isNewRelease && changesDetected) ||
      (changesDetected && (isEditing || isCloning));
    return !shouldEnableReset;
  };

  const isSaveDisabled = (isNewRelease) => {
    const isSuperseded = currentRelease.current.status === ReleaseAppConstants.SUPERSEDED;

    return isSuperseded && !isCloning || (!isNewRelease && !isEditing && !isCloning);
  };


  const isTypeDisabled = (isNewRelease) => {
    const isFinalizedOrArchived = currentReleaseCopy.stage === ReleaseAppConstants.FINALIZED || currentReleaseCopy.stage === ReleaseAppConstants.ARCHIVED;
    return !isCloning && (isFinalizedOrArchived || (!isNewRelease && !isEditing));
  };


  const isVersionDisabled = (isNewRelease) => !isNewRelease && !isCloning;

  const isStatusDisabled = (isNewRelease) => {
    const isSuperseded = currentRelease.current.status === ReleaseAppConstants.SUPERSEDED;
    return isSuperseded && !isCloning || (!isNewRelease && !isEditing && !isCloning);
  };


  const isMarketDisabled = (isNewRelease) => !isNewRelease && !isCloning;

  const isDescriptionDisabled = (isNewRelease) => {
    const isFinalizedOrArchived = currentReleaseCopy.stage === ReleaseAppConstants.FINALIZED || currentReleaseCopy.stage === ReleaseAppConstants.ARCHIVED;
    return !isCloning && (isFinalizedOrArchived || (!isNewRelease && !isEditing));
  };

  const isPacksDisabled = (isNewRelease) => {
    const isFinalizedOrArchived = currentReleaseCopy.stage === ReleaseAppConstants.FINALIZED || currentReleaseCopy.stage === ReleaseAppConstants.ARCHIVED;
    return !isCloning && (isFinalizedOrArchived || (!isNewRelease && !isEditing));
  };

  const isAddPackageDisabled = (isNewRelease) => {
    const isFinalizedOrArchived = currentReleaseCopy.stage === ReleaseAppConstants.FINALIZED || currentReleaseCopy.stage === ReleaseAppConstants.ARCHIVED;
    return !isCloning && (isFinalizedOrArchived || (!isNewRelease && !isEditing));
  };

  const isPackageCardDisabled = (isNewRelease) => {
    const isFinalizedOrArchived = currentReleaseCopy.stage === ReleaseAppConstants.FINALIZED || currentReleaseCopy.stage === ReleaseAppConstants.ARCHIVED;
    return !isCloning && (isFinalizedOrArchived || (!isNewRelease && !isEditing));
  };

  const isActionButtonDisabled = () => currentReleaseCopy.name !== '' && !isEditing;

  const isFingerprintButtonDisabled = () => currentReleaseCopy && currentReleaseCopy.fingerprintUrlLocation && currentReleaseCopy.fingerprintUrlLocation != '';

  function hasChanges(releaseCopy) {

    const comparisonState = currentRelease.current.name === '' ? initialReleaseState : currentRelease.current;

    for (const key in comparisonState) {
      if (Object.hasOwn(releaseCopy, key)) {
        if (key === 'packages') {
          if (!arraysAreEqual(releaseCopy[key], comparisonState[key])) {
            return true;
          }
        } else {
          if (releaseCopy[key] !== comparisonState[key]) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }


  const getOptions = (type) => {
    const currentStatus = currentRelease.current.status;
    let allOptions = [];
    let options = [];

    if (type === ReleaseAppConstants.STATUS) {
      options = currentStatus ? initialData[type]?.[currentStatus] || [] : Object.keys(initialData[type]) || [];

      if (currentStatus) {
        allOptions = [
          { value: currentStatus, label: currentStatus, disabled: true },
          ...options.map(option => ({ value: option, label: option })),
        ];
      } else {
        allOptions = [...options.map(option => ({ value: option, label: option }))];
      }
    } else {
      options = initialData?.[type] || [];
      allOptions = [...options.map(option => ({ value: option, label: option }))];
    }

    return allOptions.map(({ value, label, disabled }) => (
      <SelectItem key={value} value={value} disabled={disabled}>{label}</SelectItem>
    ));
  };



  return (
    <>
      {!isCreatePackage ? (
        <div className="relative flex justify-center mb-5 mt-4">
          <Card className="flex w-5/6 justify-center">
            <div className="w-4/5">
              <CardHeader className='mt-4'>
                <div className="flex justify-between items-center">
                  <div>
                    <CardTitle>
                      {isCloning
                        ? ReleaseAppConstants.CLONING_FROM + ' ' + currentReleaseCopy.name
                        : currentReleaseCopy.name != ''
                          ? currentReleaseCopy.name
                          : ReleaseAppConstants.CREATE_RELEASE}
                    </CardTitle>
                    {!currentReleaseCopy.name !== '' && !isCloning && (
                      <CardDescription>Make your release here.</CardDescription>
                    )}
                    {isCloning && (
                      <CardDescription>Clone your release here.</CardDescription>
                    )}
                  </div>
                  {isDisabled('action-buttons') && (
                    <div className="flex flex-row items-center space-x-6 cursor-pointer">
                      {/* Clone Icon */}
                      <div className="relative group">
                        <FontAwesomeIcon
                          icon={faClone}
                          className="h-6 w-6 transition-transform duration-200 hover:scale-110 hover:text-gray-500"
                          onClick={handleClone}
                        />
                        <span className="absolute left-1/2 transform -translate-x-1/2 top-8 text-xs text-gray-700 bg-white px-2 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                          Clone
                        </span>
                      </div>

                      {/* Edit Icon */}
                      {isDisabled('edit') ? <></> :
                        <div className="relative group">
                          <Edit
                            className="h-6 w-6 cursor-pointer transition-transform duration-200 hover:scale-110 hover:text-gray-500"
                            onClick={handleEdit}
                          />
                          <span className="absolute left-1/2 transform -translate-x-1/2 top-8 text-xs text-gray-700 bg-white px-2 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                            Edit
                          </span>
                        </div>
                      }

                      {/* FingerPrint Icon */}
                      {isDisabled('fingerprint-button') &&
                        <div className="relative group">
                          <PiFingerprintSimpleFill
                            className="h-7 w-7 cursor-pointer transition-transform duration-200 hover:scale-110 hover:text-gray-500"
                            onClick={handleDownloadFingerprint}
                          />
                          <span className="absolute left-1/2 transform -translate-x-1/2 top-8 text-xs text-gray-700 bg-white px-2 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                            Fingerprint
                          </span>
                        </div>}



                      {/* Export Icon */}
                      <div className="relative group">
                        <FontAwesomeIcon
                          icon={faFileExport}
                          className="h-6 w-6 cursor-pointer transition-transform duration-200 hover:scale-110 hover:text-gray-500"
                          onClick={handleExport}
                        />
                        <span className="absolute left-1/2 transform -translate-x-1/2 top-8 text-xs text-gray-700 bg-white px-2 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                          Export
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardContent>
                <div className='mt-3'>
                  <div className="grid w-full items-center gap-4">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="type">Type</Label>
                      <Select
                        onValueChange={(value) => handleInputChange({ target: { name: 'type', value } })}
                        value={currentReleaseCopy ? currentReleaseCopy.type : ""}
                        defaultValue=""
                        disabled={isDisabled('type')}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Type</SelectLabel>
                            <SelectItem value="Maintenance">Maintenance</SelectItem>
                            <SelectItem value="Feature">Feature</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="version">Version</Label>
                      <Input
                        id="version"
                        name="version"
                        value={currentReleaseCopy.version}
                        onChange={handleInputChange}
                        placeholder="Enter version"
                        disabled={isDisabled('version')}
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="status">Status</Label>
                      <Select
                        onValueChange={(value) => handleInputChange({ target: { name: 'status', value } })}
                        value={currentReleaseCopy.status}
                        defaultValue=""
                        disabled={isDisabled('status')}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Status</SelectLabel>
                            {getOptions(ReleaseAppConstants.STATUS)}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-col space-y-1.5 mb-5">
                      <Label htmlFor="market">Customer</Label>
                      <Select
                        onValueChange={(value) => handleInputChange({ target: { name: 'market', value } })}
                        value={currentReleaseCopy.market}
                        disabled={isDisabled('market')}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Customer</SelectLabel>
                            {getOptions(ReleaseAppConstants.CUSTOMERS)}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-1.5 mb-5">
                    <Label htmlFor="description">Description</Label>
                    <Textarea
                      className="resize-none h-28"
                      placeholder="Release description."
                      id="description"
                      name="description"
                      value={currentReleaseCopy.description}
                      onChange={handleInputChange}
                      disabled={isDisabled('description')}
                    ></Textarea>
                  </div>

                  <div className="flex items-center">
                    <Label htmlFor="packs">Create Packs for Release</Label>
                    <div className="relative group ml-5 flex items-center justify-center">
                      <button
                        className={`flex items-center justify-center rounded-full w-10 h-8 transition-transform duration-200 
                        ${isDisabled('packs') ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700 hover:bg-gray-600 hover:scale-110'} 
                        text-white`}
                        onClick={handleCreatePackage}
                        disabled={isDisabled('packs')}
                      >
                        <FontAwesomeIcon icon={faPlus} className="text-white" />
                      </button>
                    </div>
                  </div>

                  <PackageTable
                    setIsCreatePackage={setIsCreatePackage}
                    setSelectedPackageForEdit={setSelectedPackageForEdit}
                    handleRemoveItem={handleRemoveItem}
                    releasePackages={currentReleaseCopy.packages}
                    handleButtonClick={handleButtonClick}
                    isDisabled={isDisabled}
                    openCommand={openCommand}
                    setOpenCommand={setOpenCommand}
                    allPackageData={allPackageData}
                    handleItemClick={handleItemClick}
                  />

                </div>
              </CardContent>
              <CardFooter className="flex justify-between mt-10">
                <div className="flex space-x-2">
                  <Button className="flex items-center bg-gray-700 space-x-1" onClick={handleReset} disabled={isDisabled('reset')}>
                    <FontAwesomeIcon icon={faRefresh} />
                    <span>Reset</span>
                  </Button>
                </div>
                <div className="flex space-x-2">
                  <Button className="flex items-center bg-gray-700 space-x-1" onClick={handleCancelRelease} >
                    <FontAwesomeIcon icon={faCancel} />
                    <span>Cancel</span>
                  </Button>
                  <Button
                    className="flex items-center bg-gray-700 space-x-1"
                    onClick={
                      isEditing && !isCloning ? handleUpdateRelease : handleCreateRelease
                    }
                    disabled={isDisabled('save')}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    <span>{!isEditing && !isCloning ? ReleaseAppConstants.SAVE_BUTTON_TEXT
                      : isCloning ? ReleaseAppConstants.CLONE_BUTTON_TEXT
                        : ReleaseAppConstants.UPDATE_BUTTON_TEXT}</span>
                  </Button>
                </div>
              </CardFooter>
            </div>
          </Card>
        </div>
      ) : (
        <PopupDialog isOpen={isCreatePackage} onClose={() => setIsCreatePackage(false)} className={"w-full md:w-3/4 h-auto max-w-none flex flex-col gap-2 mb-4 ml-auto mr-auto"}>
          <CreatePackage pageList={createPackagePages} selectedPackageForEdit={selectedPackageForEdit} setIsCreatePackage={setIsCreatePackage} />
        </PopupDialog>
      )}
    </>
  );
};

export default CreateRelease;