import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/ui/table';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import apiService from "@/api/ApiServices";
import { Checkbox } from "@/components/ui/checkbox";
import ReleaseAppConstants from '../../utils/ReleaseAppConstants';


const PackageComponentDetails = ({ packageData, setPackageData, getOptions }) => {

  const [components, setComponents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const selectedComponents = useRef([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ "type": "" });
  const [totalPages, setTotalPages] = useState(0);
  const observer = useRef();

  const headers = [
    { name: <Checkbox disabled={true} />, width: 'w-[5%]' },
    { name: 'Component', width: 'w-2/5' },
    { name: 'Type', width: 'w-[12%]' },
    { name: 'Created Date', width: 'w-[20%]' }
  ];

  const fetchComponents = (pageNumber = 0) => {
    const params = {
      pageSize: 5,
      pageNumber: pageNumber,
      filters: filter,
      searchString: searchQuery
    };

    const encodedParams = encodeURIComponent(JSON.stringify(params));

    apiService.getWithRequestParam(ReleaseAppConstants.COMPONENTS_API, encodedParams)
      .then((response) => {
        setComponents((prevComponents) => [...prevComponents, ...response.data]);
        setTotalPages(response.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching Components: ', error);
        setLoading(false);
      });
  };

  const lastComponentElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < totalPages - 1) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, page, totalPages]
  );

  const isComponentSelected = (component) => {
    return packageData.components.some((c) => c.name === component.name);
  };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setFilter({ type: value });
    setPage(0);
    setComponents([]);
    setPackageData({
      ...packageData,
      [name]: value
    });

  };

  const handleSelectedComponent = (component, isChecked) => {
    if (isChecked) {
      selectedComponents.current = [...selectedComponents.current, component];
    } else {
      selectedComponents.current = selectedComponents.current.filter(
        (c) => c.name !== component.name
      );
    }

    setPackageData({
      ...packageData,
      components: selectedComponents.current
    });
  };


  useEffect(() => {
    setLoading(true);
    fetchComponents(page);
  }, [page, filter, searchQuery]);

  useEffect(() => {
    if (packageData?.components?.length > 0) {
      const firstComponent = packageData.components[0];
      packageData.componentType = firstComponent.type;
    }

    if (packageData && packageData.componentType) {
      handleInputChange({ target: { name: 'componentType', value: packageData.componentType } })
    }
    selectedComponents.current = packageData.components;
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  const setSearchItem = (value) => {
    setPage(0);
    setComponents([]);
    setSearchQuery(value)
  }
  return (
    <div className="relative flex justify-center mt-2 mb-2">
      <div className=" w-4/5 ">

        <div className="flex items-center space-x-4">
          <div className="flex flex-col space-y-1.5 w-1/2">
            <Label htmlFor="componentType" className="mb-2">Component Type</Label>
            <Select
              onValueChange={(value) =>
                handleInputChange({ target: { name: 'componentType', value } })
              }
              value={filter.type}
              defaultValue=""
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Type</SelectLabel>
                  {getOptions(ReleaseAppConstants.COMPONENT_TYPE)}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col space-y-1.5 w-1/2">
            <Label htmlFor="search" className="mb-2">Search</Label>
            <Input
              id="search"
              name="search"
              placeholder="Search components"
              defaultValue=""
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col space-y-1.5 mt-5 mb-2">
          <Label htmlFor="components">Components</Label>
        </div>
        <div className='h-[310px] border rounded-lg w-auto mx-auto mt-1'>
          <div className='overflow-y-auto h-full'>
            <Table>
              <TableHeader className='bg-gray-100'>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableHead key={index} className={header.width}>{header.name}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {components.length > 0 ? (
                  components.map((component, index) => (
                    <TableRow
                      key={component.name}
                      ref={components.length === index + 1 ? lastComponentElementRef : null}
                    >
                      <TableCell className="font-medium">
                        <Checkbox
                          checked={isComponentSelected(component)}
                          onCheckedChange={(isChecked) => handleSelectedComponent(component, isChecked)}
                        />
                      </TableCell>
                      <TableCell className="font-medium">{component.name}</TableCell>
                      <TableCell className="font-medium">{component.type}</TableCell>
                      <TableCell className="font-medium">{formatDate(component.createdDateTime)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length} className="text-center py-4">
                      No components available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {loading && <p>Loading...</p>}
          </div>
        </div>
      </div>
    </div>
  );

}

export default PackageComponentDetails;