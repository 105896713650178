import React from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader, DialogTitle,DialogDescription
} from "@/components/ui/dialog";
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

const PopupDialog = ({ isOpen, onClose, children, className }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogTrigger asChild>
        <Button className="hidden" />
      </DialogTrigger>
      <DialogContent className={className}>
        <DialogHeader className="ml-8 mt-2">
          <VisuallyHidden>
          <DialogTitle>Popup Dialog</DialogTitle>
          <DialogDescription>Package details</DialogDescription>
          </VisuallyHidden>
        </DialogHeader>

        <div className="flex-1 overflow-auto hide-scrollbar">
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PopupDialog;
