import { useState, useEffect } from 'react';
import { IoIosSearch } from "react-icons/io";
import { Input } from "@/components/ui/input";
import { Button } from '@/components/ui/button';
import { EditDialog } from '../components/main/EditDialog';
import noDataImage from '../assets/no-data.jpg';
import { Download } from "lucide-react";
import { Loader2, RefreshCcw, Edit } from "lucide-react";
import { motion } from "framer-motion";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import apiService from '@/api/ApiServices';
import ReleaseAppUtils from '../utils/ReleaseAppUtils';
import ReleaseAppConstants from '../utils/ReleaseAppConstants';


export default function Components() {
  const [components, setComponents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 7;
  const [selectedComponent, setSelectedComponent] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [isSelectedComponentUpdated, setSelectedComponentUpdated] = useState(false);
  const [initialData, setInitialData] = useState(ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA));

  const fetchOrSyncComponents = (page = 0, isSync = false) => {
    const params = {
      pageSize: itemsPerPage,
      pageNumber: page,
      filters: isSync ? {} : selectedComponent,
      searchString: isSync ? "" : searchTerm
    };

    const encodedParams = encodeURIComponent(JSON.stringify(params));

    const endpoint = isSync ? "components/refresh" : "/components";

    if (isSync) {
      setIsLoading(true);
    }

    apiService.getWithRequestParam(endpoint, encodedParams)
      .then(response => {
        setComponents(response.data);
        setTotalPages(response.totalPages);

        if (isSync) {
          setIsLoading(false);
          setCurrentPage(0);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOrSyncComponents(currentPage);
  }, [currentPage, searchTerm, selectedComponent]);

  useEffect(() => {
    if (isSelectedComponentUpdated) {
      fetchOrSyncComponents(currentPage);
      setSelectedComponentUpdated(false);
    }
  }, [isSelectedComponentUpdated]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
    fetchOrSyncComponents(0);
  };

  const handleSelect = (value) => {

    setSelectedComponent(value === 'All' ? {} : { "type": value });
    setCurrentPage(0);
    fetchOrSyncComponents(0);
  };

  const openDialog = (component) => {
    setCurrentComponent(component);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };


  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getDisplayedPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbers = 5;
    const endPage = Math.min(totalPages, totalNumbers);

    if (currentPage < 3) {
      for (let i = 0; i < endPage - 1; i++) {
        pageNumbers.push(i);
      }
      if (totalPages > endPage) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages - 1);
      }
    } else if (currentPage >= totalPages - 3) {
      if (totalPages > endPage) pageNumbers.push('...');
      for (let i = totalPages - endPage; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(0, '...');
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...', totalPages - 1);
    }

    return pageNumbers;
  };

  const getOptions = (type) => {
    const options = initialData?.[type] || [];
    const allOption = { value: 'All', label: `All COMPONENTS` };
    let allOptions = '';
    if (type === ReleaseAppConstants.STATUS) {
      allOptions = [allOption, ...Object.keys(options).map(key => ({ value: key, label: key }))];
    } else {
      allOptions = [allOption, ...options.map(option => ({ value: option, label: option }))];
    }

    return allOptions.map(({ value, label }) => (
      <SelectItem key={value} value={value}>{label}</SelectItem>
    ));
  };

  return (
    <div className='body-container'>
      <div className='data-container'>
        <div className='relative mt-4 mb-4 w-full md:w-2/4 mx-auto'>
          <Input className='search-bar' id="serachbox" type="text" placeholder="Search" onChange={handleSearch} />
          <IoIosSearch className='absolute top-1/2 right-3 transform -translate-y-1/2 text-4xl text-gray-500 p-1 rounded' />
        </div>

        <div className='mb-7 w-full md:w-2/4 mx-auto flex justify-between'>
          <div>
            <Select onValueChange={handleSelect} defaultValue='All'>
              <SelectTrigger className="w-[160px] h-[30px] shadow appearance-none border rounded-lg ring-1 ring-slate-600">
                <SelectValue placeholder="Component" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Components</SelectLabel>
                  {getOptions(ReleaseAppConstants.COMPONENT_TYPE)}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            {isLoading ? (
              <Button disabled className='w-[130px] h-[30px]'>
                <Loader2 className="mr-2 h-4 w-3 animate-spin" />
                please wait
              </Button>
            ) : (
              <Button
                className='w-[130px] h-[30px] bg-gray-700 flex items-center justify-center hover:bg-gray-600 focus:outline-none'
                onClick={() => fetchOrSyncComponents(0, true)}
              >
                <RefreshCcw className="mr-2 h-4 w-4" />
                Sync
              </Button>
            )}
          </div>
        </div>

        <div className="relative flex flex-col gap-y-8 w-full h-[475] mx-auto mb-1">
          {components.length > 0 ? (
            <>
              <div className='h-[475px] border rounded-lg w-3/4 mx-auto  mt-1'>
                <Table>
                  <TableHeader className='bg-gray-100'>
                    <TableRow>
                      <TableHead className="w-3/6">Component</TableHead>
                      <TableHead className="w-[17.5%]">Type</TableHead>
                      <TableHead className="w-[17.5%]">Edit</TableHead>
                      <TableHead className="w-[15%]">Download</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {components.map(component => (
                      <TableRow key={component.name}>
                        <TableCell className="font-medium">{component.name}</TableCell>
                        <TableCell className="font-medium">{component.type}</TableCell>
                        <TableCell className="font-medium">
                          <motion.div whileTap={{ scale: 0.9 }}>
                            <Edit className="h-5 w-5 cursor-pointer ml-1" onClick={() => !isLoading && openDialog(component)} />
                          </motion.div>
                        </TableCell>
                        <TableCell className="font-medium">
                          <motion.div whileTap={{ scale: 0.9 }}>
                            <a href={component.location} className='md:w-20 h-7 flex items-center justify-center' download>
                              <Download />
                            </a>
                          </motion.div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious href="#" onClick={handlePrevious} disabled={currentPage === 0} />
                  </PaginationItem>

                  {getDisplayedPageNumbers().map((number, index) => (
                    <PaginationItem key={index}>
                      {number === '...' ? (
                        <PaginationEllipsis />
                      ) : (
                        <PaginationLink
                          href="#"
                          onClick={() => setCurrentPage(number)}
                          isActive={number === currentPage}
                        >
                          {number + 1}
                        </PaginationLink>
                      )}
                    </PaginationItem>
                  ))}

                  <PaginationItem>
                    <PaginationNext href="#" onClick={handleNext} disabled={currentPage === totalPages - 1} />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </>
          ) : (
            <div className="flex justify-center items-center mb-8">
              <img src={noDataImage} alt="No data" className="w-80 h-full object-contain" />
            </div>
          )}
        </div>
      </div>
      <EditDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} closeDialog={closeDialog} component={currentComponent}
        setSelectedComponentUpdated={setSelectedComponentUpdated} />
    </div>
  );
}
