import React, { useState, useEffect } from 'react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/ui/table';
import { Checkbox } from "@/components/ui/checkbox";

const TableComponent = ({ headers, data }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className='h-[310px] border rounded-lg w-auto mx-auto mt-1'>
      <div className='overflow-y-auto h-full '>
        <Table className="opacity-100 cursor-not-allowed">
          <TableHeader className='bg-gray-100'>
            <TableRow>
              {headers.map((header, index) => (
                <TableHead key={index} className={header.width}>{header.name}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.components.length > 0 ? (
              data.components.map(component => (
                <TableRow key={component.name}>
                  <TableCell className="font-medium">{component.name}</TableCell>
                  <TableCell className="font-medium">{component.type}</TableCell>
                  <TableCell className="font-medium">{formatDate(component.createdDateTime)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length} className="text-center py-4">
                  No components available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default TableComponent;
