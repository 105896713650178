// Header.js
import React from 'react';
import MainNav from './MainNav';
import MobileNav from './MobileNav';
import Avatar from "./Avatar"
import { IoIosNotifications } from 'react-icons/io';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useNavigate } from 'react-router-dom';


export default function Header() {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear authentication token
    localStorage.removeItem('initialData');
    navigate('/'); // Redirect to login page
  };
  return (
    <header className="header">
      <div className="flex justify-between h-12 items-center px-4">
        <div className="flex">
          <MainNav />
          <MobileNav />
        </div>
        <div className="flex items-center space-x-3">
          <IoIosNotifications className="text-4xl" />
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Avatar />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Profile</DropdownMenuItem>
              <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
}
