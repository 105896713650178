import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@/components/ui/button';
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import apiService from "@/api/ApiServices";
import { toast } from "@/components/ui/use-toast";

import {
  MultiSelector,
  MultiSelectorTrigger,
  MultiSelectorInput,
  MultiSelectorContent,
  MultiSelectorList,
  MultiSelectorItem,
} from "@/components/extension/multi-select";
import ReleaseAppUtils from '../../utils/ReleaseAppUtils';
import ReleaseAppConstants from '../../utils/ReleaseAppConstants';

const options = [
  { label: "WCLC", value: "WCLC" },
  { label: "ILLINOIS", value: "ILLINOIS" },
  { label: "OREGON", value: "OREGON" },
  { label: "GREECE", value: "GREECE" },
  { label: "ALBERTA", value: "ALBERTA" }
];

export function EditDialog({ isDialogOpen, setIsDialogOpen, closeDialog, component, setSelectedComponentUpdated }) {

  const [initialData, setInitialData] = useState(ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA));

  const [formState, setFormState] = useState({
    customers: [],
    purpose: '',
    location: '',
    displayName: '',
    name: ''
  });

  useEffect(() => {
    if (isDialogOpen && component) {
      const marketNames = component.customers?.map(customer => customer.name) || [];
      setFormState({
        customers: marketNames,
        purpose: component.purpose || '',
        location: component.location || '',
        displayName: component.displayName || '',
        name: component.name || '',
      });
    }
  }, [component, isDialogOpen]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleMarketsChange = (customers) => {
    setFormState(prevState => ({
      ...prevState,
      customers
    }));
  };

  const handleSave = () => {
    const payload = {
      ...formState,
      name: component.name,
    };

    apiService.patch(`/components`, payload)
      .then(response => {
        console.log('Component updated successfully:', response.data);
        closeDialog();
        resetFields();
        setSelectedComponentUpdated(true);
        toast({
          title: "Component Updated",
          description: (
            <span>
              Component <strong>{component.name}</strong> has been successfully updated.
            </span>
          ),
          duration: 5000,
          className: 'success-toast',
        });
      })
      .catch(error => {
        console.error('There was an error updating the component!', error);
        toast({
          title: "Failed to Update Component",
          description: "An error occurred while updating. Please try again.",
          className: 'error-toast',
          duration: 5000,
        });
      });
  };

  const handleCancel = () => {
    closeDialog();
    resetFields();
  };

  const resetFields = () => {
    setFormState({
      customers: [],
      purpose: '',
      location: '',
      displayName: '',
    });
  };

  const getOptions = (type) => {
    const optionsArray = initialData?.[type] || [];
    return optionsArray.map(option => ({
      value: option,
      label: option
    }));
  };




  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button className='hidden' />
      </DialogTrigger>

      <DialogContent className='w-full md:w-1/2 h-full md:h-[38rem] max-w-none flex flex-col gap-2'>
        <DialogHeader>
          <DialogTitle>Edit Component</DialogTitle>
          <DialogDescription>
            Update the component details as needed.
          </DialogDescription>
        </DialogHeader>

        {component && (
          <div className="flex justify-between gap-2 mt-3">
            <div className="flex-grow flex flex-col overflow-auto gap-3 p-2 w-1/2 h-[450px]">
              <div className="flex items-center space-x-5">
                <Label className="w-32 md:w-32">Name</Label>
                <Input
                  id="name"
                  disabled
                  defaultValue={component.name}
                  className="md:w-full h-[2.25rem]"
                />
              </div>
              <div className="flex items-center space-x-5">
                <Label htmlFor="displayname" className="w-32">Display Name</Label>
                <Input
                  id="displayName"
                  value={formState.displayName}
                  className="md:w-full h-[2.25rem]"
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex items-center space-x-5 h-28">
                <Label className="w-32">Available In</Label>
                <div className="md:w-full">
                  <MultiSelector
                    values={formState.customers}
                    onValuesChange={handleMarketsChange}
                    loop={false}
                  >
                    <MultiSelectorTrigger>
                      <MultiSelectorInput placeholder="Add Customers" />
                    </MultiSelectorTrigger>
                    <MultiSelectorContent>
                      <MultiSelectorList>
                        {getOptions(ReleaseAppConstants.CUSTOMERS).map(option => (
                          <MultiSelectorItem
                            key={option.value}  // Use option.value as key for uniqueness
                            value={option.value}
                            disabled={formState.customers.includes(option.value)}
                          >
                            {option.label}
                          </MultiSelectorItem>
                        ))}
                      </MultiSelectorList>
                    </MultiSelectorContent>
                  </MultiSelector>
                </div>

              </div>

              <div className="flex items-center space-x-5">
                <Label htmlFor="revision" className="w-32">Revision</Label>
                <Input
                  id="revision"
                  disabled
                  defaultValue={component.revision || ""}
                  className="md:w-full h-[2.25rem]"
                />
              </div>
              <div className="flex items-center space-x-5">
                <Label htmlFor="location" className="w-32">Location</Label>
                <Input
                  id="location"
                  value={formState.location}
                  className="md:w-full h-[2.25rem]"
                  onChange={handleInputChange}
                  disabled={formState.location !== ""}
                />
              </div>
              <div className="flex items-center space-x-5">
                <Label htmlFor="purpose" className="w-32">Description</Label>
                <Textarea
                  className="resize-none md:w-full"
                  placeholder="Release description."
                  id="purpose"
                  value={formState.purpose}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex justify-center p-1 mt-[8rem] mr-5 h-[200px] w-[25%]">
              <a
                href={component.location}
                onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.3)'}
                style={{ transition: 'transform 0.3s' }}
                onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'}
                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
              >
                <FontAwesomeIcon icon={faDownload} size="4x" className="text-gray-700" />
                <p className="text-center text-sm mt-2" style={{ userSelect: 'none' }}>Download</p>
              </a>
            </div>
          </div>
        )}

        <DialogFooter className='flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-2 mr-4 mt-1'>
          <Button onClick={handleCancel} className='w-1/2 h-[2.25rem] md:w-auto bg-gray-700'>Cancel</Button>
          <Button onClick={handleSave} className='w-full md:w-auto h-[2.25rem] bg-gray-700'>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
