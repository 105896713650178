import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';
import apiService from '@/api/ApiServices';
import ComponentComparison from '@/components/main/ComponentComparison';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CgArrowsExchange } from "react-icons/cg";
import { format } from 'date-fns';

const Compare = () => {
  const [releases, setReleases] = useState([]);
  const [selectedRelease1, setSelectedRelease1] = useState(null);
  const [selectedRelease2, setSelectedRelease2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = {
      pageSize: 100,
      pageNumber: 0,
      searchString: "",
      filters: {}
    };

    const encodedParams = encodeURIComponent(JSON.stringify(params));

    apiService.getWithRequestParam("/releases", encodedParams)
      .then(response => {
        if (response.data && Array.isArray(response.data)) {
          setReleases(response.data);
        } else {
          throw new Error('Unexpected response data format');
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        setError('Failed to load releases');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchSelectedRelease = (releaseName, isFirstRelease) => {
    if (releaseName) {
      apiService.getWithPathVariable("/releases", releaseName)
        .then(response => {
          if (isFirstRelease) {
            setSelectedRelease1(response);
          } else {
            setSelectedRelease2(response);
          }
        })
        .catch(error => {
          console.error('Error getting Packages', error);
        });
    }
  };

  const handleSelectRelease1 = (value) => {
    fetchSelectedRelease(value, true);
  };

  const handleSelectRelease2 = (value) => {
    fetchSelectedRelease(value, false);
  };

  const handleSwitchReleases = () => {
    if (selectedRelease1 && selectedRelease2) {
      setSelectedRelease1(prev => selectedRelease2);
      setSelectedRelease2(prev => selectedRelease1);
    }
  };

  const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  };

  const renderDifference = (field) => {
    if (selectedRelease1 && selectedRelease2) {
      if (field === 'lastUpdated') {
        const date1 = new Date(selectedRelease1[field]);
        const date2 = new Date(selectedRelease2[field]);
        const isDifferent = date1.getTime() !== date2.getTime();

        return (
          <div>
            {isDifferent ? (
              <div className="text-red-500 font-semibold">
                {dateFormatter(selectedRelease2[field])}
              </div>
            ) : (
              dateFormatter(selectedRelease2[field])
            )}
          </div>
        );
      } else if (selectedRelease1[field] !== selectedRelease2[field]) {
        return (
          <div className="text-red-500 font-semibold">
            {selectedRelease2[field]}
          </div>
        );
      }
      return (
        <span>
          {selectedRelease1[field]}
        </span>
      );
    }
    return <span>{selectedRelease1 ? selectedRelease1[field] : ''}</span>;
  };

  const getFileNameFromUrl = (url) => {
    if (!url) return <span className="text-red-500 font-semibold">Fingerprint is Absent</span>;
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  const noSelectionMessage = (
    <div className="text-gray-500 text-lg">
      <p>Please select both a Source Release and a Destination Release to see the comparison.</p>
      <p className='pt-5 pb-2'>Steps:</p>
      <ul className="list-disc pl-5">
        <li className='pb-1'>Select a Source Release from the dropdown.</li>
        <li className='pb-1'>Select a Destination Release from the dropdown.</li>
      </ul>
      <p className='pt-5'>The comparison will show the differences between the two releases.</p>
    </div>
  );




  return (
    <div className="p-8 bg-gray-100 max-h-auto min-h-[795px]">
      <h1 className="text-3xl font-bold mb-4">Compare Releases</h1>
      <h5 className="text-lg mb-8">See what's in Source Release, but not in Destination</h5>

      <div className="mb-8 flex items-center space-x-4">
        <div className="w-full max-w-xs">
          <label className="block mb-2 font-semibold text-gray-700">Source Release</label>
          <Select onValueChange={handleSelectRelease1} defaultValue="" value={selectedRelease1?.name || ""}>
            <SelectTrigger className="w-full border border-gray-300 rounded-lg py-2 px-4 text-sm">
              <SelectValue placeholder="Select Release" />
            </SelectTrigger>
            <SelectContent>
              {releases.map((release) => (
                <SelectItem key={release.name} value={release.name}>
                  {release.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className='mt-8'>
          <CgArrowsExchange
            className="text-gray-500 cursor-pointer mx-4"
            onClick={handleSwitchReleases}
            size={30}
          />
        </div>

        <div className="w-full max-w-xs">
          <label className="block mb-2 font-semibold text-gray-700">Destination Release</label>
          <Select onValueChange={handleSelectRelease2} defaultValue="" value={selectedRelease2?.name || ""}>
            <SelectTrigger className="w-full border border-gray-300 rounded-lg py-2 px-4 text-sm">
              <SelectValue placeholder="Select Release" />
            </SelectTrigger>
            <SelectContent>
              {releases.map((release) => (
                <SelectItem key={release.name} value={release.name}>
                  {release.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {(!selectedRelease1 || !selectedRelease2) ? (
        noSelectionMessage
      ) : (
        <div className="bg-white p-6 rounded-lg shadow-md space-y-4">
          <div className="grid grid-cols-3 gap-4 text-gray-700">
            <div>
              <span className="text-xl font-semibold mb-4">Release Attributes</span>
            </div>
            <div>
              <span className="text-xl font-semibold mb-4">Source Release</span>
            </div>
            <div>
              <span className="text-xl font-semibold mb-4">Destination Release</span>
            </div>

            <div>Name</div>
            <div>{selectedRelease1.name}</div>
            <div>{renderDifference('name')}</div>

            <div>Version</div>
            <div>{selectedRelease1.version}</div>
            <div>{renderDifference('version')}</div>

            <div>Customer</div>
            <div>{selectedRelease1.market}</div>
            <div>{renderDifference('market')}</div>

            <div>Type</div>
            <div>{selectedRelease1.type}</div>
            <div>{renderDifference('type')}</div>

            <div>Status</div>
            <div>{selectedRelease1.status}</div>
            <div>{renderDifference('status')}</div>

            <div>Stage</div>
            <div>{selectedRelease1.stage}</div>
            <div>{renderDifference('stage')}</div>

            <div>Last Updated</div>
            <div>
              {dateFormatter(selectedRelease1.lastUpdated)}
            </div>
            <div>
              {renderDifference('lastUpdated')}
            </div>

            <div>Description</div>
            <div>{selectedRelease1.description}</div>
            <div>{renderDifference('description')}</div>

            <div>Fingerprint URL</div>
            <div>
              {selectedRelease1?.fingerprintUrlLocation ? (
                <a
                  href={selectedRelease1.fingerprintUrlLocation}
                  className="text-blue-500 underline"
                  download
                >
                  {getFileNameFromUrl(selectedRelease1.fingerprintUrlLocation)}
                </a>
              ) : (
                <span className="font-semibold">Fingerprint is Absent</span>
              )}
            </div>
            <div>
              {selectedRelease2?.fingerprintUrlLocation ? (
                <a
                  href={selectedRelease2.fingerprintUrlLocation}
                  className={`underline ${selectedRelease1?.fingerprintUrlLocation !== selectedRelease2?.fingerprintUrlLocation ? 'text-red-500 font-semibold' : 'text-blue-500'}`}
                  download
                >
                  {getFileNameFromUrl(selectedRelease2.fingerprintUrlLocation)}
                </a>
              ) : (
                <span className="font-semibold">Fingerprint is Absent</span>
              )}
            </div>
          </div>

          {/* Component Comparison */}
          <div className="pt-8">
            <h2 className="text-xl font-semibold mb-4">Component Comparison</h2>
            <ComponentComparison
              packages1={selectedRelease1.packages}
              packages2={selectedRelease2.packages}
              releaseNameSource={selectedRelease1.name}
              releaseNameDestination={selectedRelease2.name}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Compare;
