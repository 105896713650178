import { Button } from '@/components/ui/button';
import { FaInfoCircle, FaLock } from "react-icons/fa";
import { Badge } from '@/components/ui/badge'; // Import the Badge component
import { CiLock } from "react-icons/ci";

export default function DataCard({ release, setIsViewSelectedRelease, setSelectedRelease }) {

  const handleButtonClick = () => {
    console.log("selected release : ", release);
    setIsViewSelectedRelease(true);
    setSelectedRelease(release);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  const formattedDate = formatDate(release.lastUpdated);

  const statusColors = {
    PLANNING: 'bg-blue-300',
    DEVELOPMENT: 'bg-teal-300',
    TEST: 'bg-yellow-200',
    CERTIFICATION: 'bg-purple-300',
    PRODUCTION: 'bg-green-300',
    CAT: 'bg-orange-300',
    SUPERSEDED: 'bg-red-300',
    OBSOLETE: 'bg-pink-300',
  };

  // Function to get the color based on the status
  const getStatusColor = (status) => {
    return statusColors[status] || 'bg-gray-200'; // Default color if status is not found
  };


  return (
    <div className="flex flex-col bg-gray-100 p-4 origin-center rotate-15 rounded-lg shadow-lg ring-1 ring-slate-300 w-full h-[155px] relative transform transition-all duration-200 hover:scale-100 hover:-translate-y-2 hover:ring-1  hover:ring-slate-500">
      {release.status === 'FINALIZED' && (
        <CiLock className="absolute top-3 right-3 text-xl" />
      )}
      <div className="flex-grow">
        <h2 className="text-lg font-bold mb-2">{release.name}</h2>
        <hr className="my-2 border-gray-300" /> {/* Add a horizontal line */}
        <p className="mb-2 text-xs font-sans">
          <span className="font-bold">Type:</span> <span className="font-semibold">{release.type}</span>
        </p>
        <p className="mb-4 text-xs font-sans">
          <span className="font-bold">Status changed:</span> <span className="font-semibold">{formattedDate}</span>
        </p>
      </div>

      <Badge
        variant="outline"
        className={`absolute bottom-4 text-xs left-4 ${getStatusColor(release.status)}`}
      >
        {release.status}
      </Badge>

      <Button
        className="hover:bg-gray-600 bg-gray-700 text-white h-7 w-20 rounded-br-lg absolute bottom-3 right-4" onClick={handleButtonClick}
      >
        <FaInfoCircle className="inline-block mr-2" />
        <div className='text-sm'>View</div>
      </Button>
    </div>
  );
}
