import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faLock } from '@fortawesome/free-solid-svg-icons';
import SearchDialog from './SearchDialog';

const PackageTable = ({
  setIsCreatePackage,
  setSelectedPackageForEdit,
  handleRemoveItem,
  releasePackages,
  handleButtonClick,
  isDisabled,
  openCommand,
  setOpenCommand,
  allPackageData,
  handleItemClick,
}) => {
  const [packages, setPackages] = useState(releasePackages);

  useEffect(() => {
    setPackages(releasePackages);
  }, [releasePackages]);

  const handleEdit = (item) => {
    setSelectedPackageForEdit(item);
    setIsCreatePackage(true);
  };

  const handleRemove = (item) => {
    handleRemoveItem(item);
  };

  return (
    <div className="w-full mx-auto mt-5 relative">
      {/* Scrollable Package Table */}
      <div className="relative border border-gray-300 rounded-lg shadow-md">
        <table className="w-full bg-white table-fixed">
          <thead className="bg-gray-100 text-gray-700 sticky top-0 z-10">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium w-[20%]">Package Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium w-[20%]">Description</th>
              <th className="px-6 py-3 text-left text-sm font-medium w-[50%]">Components</th>

              {/* Conditionally render the + Add column */}
              {!isDisabled('package-card') && (
                <th
                  className="px-6 py-3 text-left text-sm font-medium cursor-pointer w-[10%]"
                  onClick={handleButtonClick}
                >
                  <div className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faPlus} className="text-gray-700" />
                    <span className="text-gray-700">Add</span>
                  </div>
                </th>
              )}
            </tr>
          </thead>
        </table>

        {/* Scrollable tbody */}
        <div className="max-h-[500px] overflow-y-auto custom-scrollbar">
          <table className="w-full table-fixed bg-white">
            <tbody>
              {packages && packages.length > 0 ? (
                packages.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors table-row">
                    <td className="border px-6 py-4 text-sm font-medium text-gray-900 w-[20%]">
                      {item.name}
                    </td>
                    <td className="border px-6 py-4 text-sm text-gray-500 w-[20%]">
                      {item.purpose || 'No description'}
                    </td>
                    <td className="border px-2 py-2 w-[50%]">
                      {item.components && Array.isArray(item.components) ? (
                        <div className="w-full">
                          <table className="w-full divide-y divide-gray-200">
                            <tbody>
                              {item.components.map((component, idx) => (
                                <tr key={idx} className="text-sm text-gray-600">
                                  <td className="border px-4 py-2 w-[70%]">
                                    {/* Component Name */}
                                    {component.location ? (
                                      <a
                                        href={component.location}
                                        className="font-medium text-blue-500 hover:underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {component.name}
                                      </a>
                                    ) : (
                                      <div className="font-medium">{component.name}</div>
                                    )}
                                  </td>
                                  <td className="border px-4 py-2 w-[30%]">
                                    {/* Component Description */}
                                    <div className="text-xs text-black">
                                      {component.purpose || 'No description'}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-gray-500 text-sm">No components</p>
                      )}
                    </td>
                    {!isDisabled('package-card') && (
                      <td className="border px-6 py-4 text-center w-[10%]">
                        <div className="flex justify-center space-x-2">
                          {/* Conditionally show Lock + Trash for locked packages, or Edit + Trash for unlocked */}
                          {item.locked ? (
                            <>
                              <FontAwesomeIcon icon={faLock} className="text-gray-700 hover:text-gray-500" />
                              <button
                                className="text-gray-700 hover:text-gray-500"
                                onClick={() => handleRemove(item)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="text-gray-700 hover:text-gray-500"
                                onClick={() => handleEdit(item)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                              <button
                                className="text-gray-700 hover:text-gray-500"
                                onClick={() => handleRemove(item)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center text-gray-500 px-6 py-4">
                    No packages available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Search Dialog */}
      <SearchDialog
        open={openCommand}
        setOpen={setOpenCommand}
        data={allPackageData}
        onItemClick={handleItemClick}
      />
    </div>
  );
};

export default PackageTable;
