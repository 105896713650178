import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import apiService from '@/api/ApiServices';
import { toast } from "@/components/ui/use-toast";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";

import {
  SelectItem,
} from "@/components/ui/select";

import { FaSave, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { MdOutlineSaveAs } from "react-icons/md";
import ReleaseAppUtils from '../../utils/ReleaseAppUtils';
import ReleaseAppConstants from '../../utils/ReleaseAppConstants';

const CreatePackage = ({ pageList, selectedPackageForEdit, setIsCreatePackage }) => {

  const initialPackageState = {
    id: "",
    name: "",
    purpose: "",
    reference: "",
    releases: "",
    type: "",
    availableForMaster: false,
    availableForSit: false,
    locked: false,
    components: [],
    lastUpdatedDate: "",
    componentType: selectedPackageForEdit ? selectedPackageForEdit.type : ""
  };

  const [packageData, setPackageData] = useState(selectedPackageForEdit ? selectedPackageForEdit : initialPackageState);
  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => setCurrentPage((prev) => prev + 1);
  const handlePreviousPage = () => setCurrentPage((prev) => prev - 1);
  const [isNewPackage, setIsNewPackage] = useState(!selectedPackageForEdit || selectedPackageForEdit?.name === '');
  const [initialData, setInitialData] = useState(ReleaseAppUtils.getItem(ReleaseAppConstants.INITIAL_DATA));

  const isDisabled = (key) => {
    switch (key) {
      case 'package-name':
        return isPackageNameDisabled();
      default:
        return false;
    }
  };

  const isPackageNameDisabled = () => {
    return !isNewPackage && !!selectedPackageForEdit?.name;
  };

  const pages = pageList.map((page, index) => ({
    icon: page.icon,
    title: page.title,
    description: page.description,
    content: page.content,
  }));

  const handleCreatePackage = () => {

    packageData.lastUpdatedDate = new Date().toISOString();
    apiService.post(ReleaseAppConstants.PACKAGES_API, packageData)
      .then(response => {
        console.log('Package create successfully:', response);
        setIsCreatePackage(false);
        toast({
          title: "New Package Created",
          description: (
            <span>
              Your pack <strong>{packageData.name}</strong> has been successfully created.
            </span>
          ),
          duration: 5000,
          className: 'success-toast',
        });
      })
      .catch(error => {
        console.error('Error creating package:', error);
        toast({
          title: "Failed to Create Package",
          description: "An error occurred while creating the package. Please try again.",
          className: 'error-toast',
          duration: 5000,
        });
      });
  };

  const handleUpdatePackage = () => {

    packageData.lastUpdatedDate = new Date().toISOString();
    apiService.patch(ReleaseAppConstants.PACKAGES_API, packageData)
      .then(response => {
        console.log('Package Update successfully:', response);
        setIsCreatePackage(false);
        toast({
          title: "Package Updated",
          description: (
            <span>
              Your pack <strong>{packageData.name}</strong> has been successfully updated.
            </span>
          ),
          duration: 5000,
          className: 'success-toast',
        });
      })
      .catch(error => {
        console.error('Error creating package:', error);
        toast({
          title: "Failed to Update Package",
          description: "An error occurred while updating the package. Please try again.",
          className: 'error-toast',
          duration: 5000,
        });
      });
  };

  const getOptions = (type) => {
    const options = initialData?.[type] || [];
    let allOptions = '';
    if (type === ReleaseAppConstants.STATUS) {
      allOptions = [...Object.keys(options).map(key => ({ value: key, label: key }))];
    } else {
      allOptions = [...options.map(option => ({ value: option, label: option }))];
    }

    return allOptions.map(({ value, label }) => (
      <SelectItem key={value} value={value}>{label}</SelectItem>
    ));
  };

  return (
    <div className="relative flex flex-col items-center">
      <div className="mt-4 mb-6 flex items-center justify-center space-x-4">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            <motion.div
              className={`p-3 rounded-full cursor-pointer ${currentPage >= index + 1
                ? 'bg-black text-white shadow-lg bg-gray-700'
                : 'bg-gray-400 text-white'
                }`}
              style={{
                boxShadow: currentPage >= index + 1 ? '0px 4px 15px rgba(0, 0, 0, 0.3)' : 'none',
              }}
              animate={{
                scale: currentPage === index + 1 ? 1.2 : 0.9,
                opacity: currentPage === index + 1 ? 1 : 0.7,
              }}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 20,
              }}
            >
              {page.icon}
            </motion.div>
            {index < pages.length - 1 && (
              <motion.div
                className={`h-1 w-10 rounded-full ${currentPage > index + 1 ? 'bg-gray-700' : 'bg-gray-400'
                  }`}
                animate={{
                  scaleX: currentPage > index + 1 ? 1.5 : 0.9,
                }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 20,
                }}
              />
            )}
          </React.Fragment>

        ))}
      </div>

      {pages.map((page, index) =>
        currentPage === index + 1 ? (
          <Card key={index} className="w-5/6 h-auto max-h-[70vh] min-h-[70vh] overflow-y-auto">
            <CardHeader className="mt-3">
              <CardTitle>{page.title}</CardTitle>
              <CardDescription>{page.description}</CardDescription>
            </CardHeader>
            <CardContent>{React.cloneElement(page.content, {
              packageData: packageData,
              setPackageData: setPackageData,
              isDisabled: isDisabled,
              getOptions: getOptions
            })}</CardContent>
          </Card>
        ) : null
      )}

      <div className='mt-4 flex items-center justify-center space-x-4'>
        <Button
          className="bg-gray-700"
          onClick={handlePreviousPage}
          disabled={currentPage <= 1}
        >
          <FaArrowLeft size={20} />
        </Button>
        <Button
          className="bg-gray-700"
          onClick={handleNextPage}
          disabled={currentPage >= pages.length}
        >
          <FaArrowRight size={20} />
        </Button>
        <Button
          className="bg-gray-700"
          disabled={currentPage < pages.length}
          onClick={packageData.id == '' ? handleCreatePackage : handleUpdatePackage}
        >
          {packageData.id == '' ? <FaSave size={20} /> : <MdOutlineSaveAs size={20} />}
        </Button>
      </div>
    </div>
  );
};

export default CreatePackage;
