class ReleaseAppUtils {

    static getItem(key) {
        const data = localStorage.getItem(key);
        if (data) {
            try {
                return JSON.parse(data);
            } catch (error) {
                console.error(`Error parsing data from localStorage with key "${key}":`, error);
                return null;
            }
        }
        return null;
    }

    static setItem(key, value) {
        try {
            const data = JSON.stringify(value);
            localStorage.setItem(key, data);
        } catch (error) {
            if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
                console.error('LocalStorage is full. Consider freeing up some space.');
            } else {
                console.error('Error saving to localStorage:', e);
            }
        }
    }

    static removeItem(key) {
        localStorage.removeItem(key);
    }

    static clearAll() {
        localStorage.clear();
    }

    static getInitialData() {
        return this.getItem('initialData');
    }

    static setInitialData(data) {
        this.setItem('initialData', data);
    }
}

export default ReleaseAppUtils;
