import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/main/Header';
import Footer from './components/main/Footer';

const MainLayout = () => {
  return (
    <div>
      <Header />
      <main>
        <Outlet />
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default MainLayout;
