import React from 'react';
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiService from '@/api/ApiServices';

const Login = () => {
    const form = useForm({
        defaultValues: {
            username: "",
            password: "",
        },
    });

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {

            const { username, password } = data;
            apiService.setCredentials(username, password);
            navigate('/releases');

        } catch (error) {
            console.error('Login failed', error);
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-200 shadow "> {/* Set background for the entire container */}
            <Card className="w-[400px] bg-gray-900 shadow-xl text-white"> {/* Set card background to black and text to white */}
                <CardHeader>
                    <CardTitle className="text-white">Login</CardTitle>
                    <CardDescription className="text-white">
                        Enter your credentials to access your account.
                    </CardDescription>
                </CardHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <CardContent className="space-y-4">
                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="text-white">Username</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Enter your username" className="bg-gray-800 text-white" {...field} />
                                        </FormControl>
                                        <FormMessage className="text-white" />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="text-white">Password</FormLabel>
                                        <FormControl>
                                            <Input type="password" placeholder="Enter your password" className="bg-gray-800 text-white" {...field} />
                                        </FormControl>
                                        <FormMessage className="text-white" />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                        <CardFooter className="flex justify-end">
                            <Button type="submit" className="bg-white text-black hover:bg-white">Login</Button>
                        </CardFooter>
                    </form>
                </Form>
            </Card>
        </div>

    );
};

export default Login;
