import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from "@/components/ui/toaster";
import MainLayout from './MainLayout';
import AuthLayout from './AuthLayout';
import Login from './pages/Login';
import Releases from './pages/Releases';
import Components from './pages/Components';
import Packages from './pages/Packages';
import ProtectedRoute from './ProtectedRoute';
import Compare from './pages/Compare';

function App() {
  return (
    <Router>
      <Routes>
        {/* Authentication routes */}
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Login />} />
        </Route>

        {/* Authenticated routes */}
        <Route path="/*" element={<MainLayout />}>
          <Route path="releases" element={<ProtectedRoute element={<Releases />} />} />
          <Route path="components" element={<ProtectedRoute element={<Components />} />} />
          <Route path="compare" element={<ProtectedRoute element={<Compare />} />} />
          {/* <Route path="packages" element={<ProtectedRoute element={<Packages />} />} /> */}
        </Route>
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
