import { useState } from 'react';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Menu as MenuIcon } from 'lucide-react';
import { FaRegCalendarAlt, FaBoxOpen, FaCogs } from 'react-icons/fa';

const mobileItems = [
  { name: 'Releases', icon: <FaRegCalendarAlt /> },
  { name: 'Packages', icon: <FaBoxOpen /> },
  { name: 'Components', icon: <FaCogs /> },
];

export default function MobileNav() {
  const [open, setOpen] = useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="md:hidden">
          <MenuIcon />
        </Button>
      </SheetTrigger>

      <SheetContent side="left" className="w-64">
        <div className="flex flex-col items-start p-4">
          <div className="w-24 h-12 mb-4 border-b border-gray-300">
            <img src="https://mma.prnewswire.com/media/452284/Inspired_White_Logo.jpg?p=publish" className="w-15 h-10" />
          </div>
          {mobileItems.map((item, index) => (
            <button
              key={index}
              className="flex items-center gap-2 p-2 hover:bg-gray-200 rounded transition-colors duration-200"
              onClick={() => {
                setOpen(false);
              }}
            >
              {item.icon}
              {item.name}
            </button>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}
