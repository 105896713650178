import React from 'react';
import { Label } from "@/components/ui/label";
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox';
import { Textarea } from "@/components/ui/textarea"
import TableComponent from './TableComponent';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const PackagePreview = ({ packageData }) => {

  const headers = [
    { name: 'Component', width: 'w-2/5' },
    { name: 'Type', width: 'w-[12%]' },
    { name: 'Created Date', width: 'w-[20%]' }
  ];

  return (
    <div className="relative flex justify-center mt-2 mb-2">
      <div className=" w-4/5 ">
        <div className="grid w-full items-center gap-4">
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="name" className="mb-2" >Name</Label>
            <Input id="name"
              name="name"
              value={packageData.name}
              placeholder="Enter name"
              disabled={true} />
          </div>
        </div>
        <div className="flex flex-col space-y-1.5 mt-3">
          <Label htmlFor="type" className="mb-2">Package Type</Label>
          <Select
            value={packageData.type}
            defaultValue=""
            disabled={true}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Type</SelectLabel>
                <SelectItem value="OS">OS</SelectItem>
                <SelectItem value="UI">UI</SelectItem>
                <SelectItem value="IKERNEL">IKERNEL</SelectItem>
                <SelectItem value="GAME">GAME</SelectItem>
                <SelectItem value="CONFIG">CONFIG</SelectItem>
                <SelectItem value="OTHER">OTHER</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col space-y-1.5 mt-5 mb-2">
          <Label htmlFor="Available for ">Available for</Label>
          <div className="flex items-center space-x-8">
            <div className="flex items-center mt-2 mb-2">
              <Checkbox
                id="availableForSit"
                name="availableForSit"
                checked={packageData.availableForSit}
                disabled={true}
              />
              <Label htmlFor="availableForSit" className="ml-2">SIT</Label>
            </div>
            <div className="flex items-center mt-2 mb-2">
              <Checkbox
                id="availableForMaster"
                name="availableForMaster"
                checked={packageData.availableForMaster}
                disabled={true}
              />
              <Label htmlFor="availableForMaster" className="ml-2 ">Master</Label>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-1.5 mt-2 mb-2">
          <Label htmlFor="purpose" className="mt-2 mb-2">Purpose</Label>
          <Textarea className="resize-none h-28" placeholder="Purpose of the package."
            id="purpose"
            name="purpose"
            value={packageData.purpose}
            disabled={true}>
          </Textarea>
        </div>

        <div className="flex flex-col space-y-1.5 mt-5 mb-3">
          <Label htmlFor="status">Components</Label>
        </div>
        <div>
          <TableComponent headers={headers} data={packageData} />
        </div>

      </div>
    </div>
  );

}

export default PackagePreview;