import React from 'react';
import { Label } from "@/components/ui/label";
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox';
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import ReleaseAppConstants from '../../utils/ReleaseAppConstants';

const PackageDetails = ({ packageData, setPackageData, isDisabled, getOptions }) => {

  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setPackageData({
      ...packageData,
      [name]: value
    });
  };



  return (
    <div className="relative flex justify-center mt-2 mb-2">
      <div className=" w-4/5 ">

        <div className="grid w-full items-center gap-4">
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="name" className="mb-2" >Name</Label>
            <Input id="name"
              name="name"
              value={packageData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
              disabled={isDisabled('package-name')} />
          </div>
        </div>
        <div className="flex flex-col space-y-1.5 mt-3">
          <Label htmlFor="type" className="mb-2">Package Type</Label>
          <Select
            onValueChange={(value) =>
              handleInputChange({ target: { name: 'type', value } })
            }
            value={packageData.type}
            defaultValue=""
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Type</SelectLabel>
                {getOptions(ReleaseAppConstants.PACKAGE_TYPE)}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col space-y-1.5 mt-5 mb-2">
          <Label htmlFor="Available for ">Available for</Label>
          <div className="flex items-center space-x-8">
            <div className="flex items-center mt-2 mb-2">
              <Checkbox
                id="availableForSit"
                name="availableForSit"
                checked={packageData.availableForSit}
                onCheckedChange={(value) => handleInputChange({ target: { name: 'availableForSit', value } })}
              />
              <Label htmlFor="availableForSit" className="ml-2">SIT</Label>
            </div>
            <div className="flex items-center mt-2 mb-2">
              <Checkbox
                id="availableForMaster"
                name="availableForMaster"
                checked={packageData.availableForMaster}
                onCheckedChange={(value) => handleInputChange({ target: { name: 'availableForMaster', value } })}
              />
              <Label htmlFor="availableForMaster" className="ml-2 ">Master</Label>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-1.5 mt-4 mb-4">
          <Label htmlFor="purpose" className="mt-2 mb-2">Purpose</Label>
          <Textarea className="resize-none h-36" placeholder="Purpose of the package."
            id="purpose"
            name="purpose"
            value={packageData.purpose}
            onChange={handleInputChange}>
          </Textarea>
        </div>

      </div>
    </div>
  );

}

export default PackageDetails;