import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FaSearch } from 'react-icons/fa';

const ComponentComparison = ({ packages1, packages2, releaseNameSource, releaseNameDestination }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchVisible(false);
        setSearchQuery('');
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const componentToPackage = useMemo(() => {
    const map1 = new Map();
    const map2 = new Map();
    const details1 = new Map();
    const details2 = new Map();

    packages1.forEach(pkg => {
      pkg.components.forEach(component => {
        if (!map1.has(component.name)) {
          map1.set(component.name, []);
          details1.set(component.name, { type: component.type, url: component.location });
        }
        map1.get(component.name).push(pkg.name);
      });
    });

    packages2.forEach(pkg => {
      pkg.components.forEach(component => {
        if (!map2.has(component.name)) {
          map2.set(component.name, []);
          details2.set(component.name, { type: component.type, url: component.location });
        }
        map2.get(component.name).push(pkg.name);
      });
    });

    return {
      componentToPackage1: map1,
      componentToPackage2: map2,
      componentDetails1: details1,
      componentDetails2: details2
    };
  }, [packages1, packages2]);

  const allComponentNames = useMemo(() => {
    const allNames = new Set([
      ...componentToPackage.componentToPackage1.keys(),
      ...componentToPackage.componentToPackage2.keys()
    ]);
    return Array.from(allNames);
  }, [componentToPackage]);

  const filteredComponentNames = useMemo(() => {
    return allComponentNames.filter(componentName =>
      componentName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [allComponentNames, searchQuery]);

  const sortedComponentNames = useMemo(() => {
    return filteredComponentNames.sort((a, b) => a.localeCompare(b));
  }, [filteredComponentNames]);

  return (
    <div className="overflow-x-auto">
      <div className="relative min-h-[580px] max-h-[580px] overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr className="h-16">
              <th className="relative px-6 py-4 text-left text-s font-medium text-gray-500 uppercase tracking-wider">
                <div className="flex items-center h-full" ref={searchRef}>
                  {isSearchVisible ? (
                    <input
                      type="text"
                      placeholder="Search components..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full bg-gray-50 border-b border-gray-300 text-sm outline-none transition-all duration-300 ease-in-out"
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={() => setIsSearchVisible(true)}
                      className="flex items-center cursor-pointer transition-opacity duration-300 ease-in-out"
                    >
                      Component Name <FaSearch size={15} className="ml-3 text-gray-500" />
                    </span>
                  )}
                </div>
              </th>
              <th className="px-6 py-4 text-left text-s font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-4 text-left text-s font-medium text-gray-500 uppercase tracking-wider">{releaseNameSource} (Source)</th>
              <th className="px-6 py-4 text-left text-s font-medium text-gray-500 uppercase tracking-wider">{releaseNameDestination} (Destination)</th>
              <th className="px-6 py-4 text-left text-s font-medium text-gray-500 uppercase tracking-wider">Packages Name</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedComponentNames.map(componentName => {
              const packagesInRelease1 = componentToPackage.componentToPackage1.get(componentName) || [];
              const packagesInRelease2 = componentToPackage.componentToPackage2.get(componentName) || [];
              const details1 = componentToPackage.componentDetails1.get(componentName) || {};
              const details2 = componentToPackage.componentDetails2.get(componentName) || {};

              const isPresentInSource = packagesInRelease1.length > 0;
              const isPresentInDestination = packagesInRelease2.length > 0;

              const uniquePackages = Array.from(new Set([...packagesInRelease1, ...packagesInRelease2]));

              return (
                <tr key={componentName}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {details1.location && details1.location !== '' ?
                      <a href={details1.location || '#'} className="text-blue-500 underline">
                        {componentName}
                      </a> :
                      <span>{componentName}</span>
                    }
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {details1.type || details2.type || 'Unknown'}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isPresentInSource ? 'bg-green-100' : 'bg-red-100'}`}>
                    {isPresentInSource ? 'Present' : 'Absent'}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isPresentInDestination ? 'bg-green-100' : 'bg-red-100'}`}>
                    {isPresentInDestination ? 'Present' : 'Absent'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {uniquePackages.length > 0
                      ? uniquePackages.join(', ')
                      : 'Not in any package'
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComponentComparison;
