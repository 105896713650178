import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import apiService from '@/api/ApiServices';

const ProtectedRoute = ({ element: Component }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null); 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await apiService.get('authenticate/login'); 

        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Token validation failed', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false); 
      }
    };

    validateToken();
  }, []);

  // Show a loading spinner while checking the token
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-4 border-gray-500 border-t-transparent border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  // If authenticated, show the component; otherwise, redirect to login
  return isAuthenticated ? Component : <Navigate to="/" state={{ from: location }} />;
};

export default ProtectedRoute;
